<template>
    <section class="pt-6">
        <div class="project-list-wrapper">
            <div class="columns">
                <div class="column">
                    <div class="container">
                        <b-table
                            :data="data"
                            :bordered="true"
                            :striped="true"
                            :hoverable="true"
                            :loading="isLoading"
                            :focusable="false"
                            :mobile-cards="false"
                            :paginated="true"
                            :per-page="20"
                            sort-icon= 'arrow-up'
                            sort-icon-size= 'is-small'                            
                            :pagination-simple="false"
                            pagination-position="bottom"                            
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            >
                            <b-table-column  class="parent-element" field="name" label="Name" v-slot="props" searchable>
                                <router-link :to="{path: 'projects/' + props.row.key}" class="nav-link">
                                    <span class="icon-text">
                                        <span>{{ props.row.name }}</span>
                                        <!-- <span class="icon is-small">
                                            <i class="fal fa-chevron-right"></i>
                                        </span>                                     -->
                                    </span>   
                                </router-link>
                             
                                <!-- <router-link :to="{path: 'projects-table/project/' + props.row.key}" class="nav-link"> <p>{{ props.row.name }}</p></router-link>     -->
                            </b-table-column>
                            <b-table-column field="dueDate" label="Due Date" v-slot="props" width="400px" sortable>
                                <span v-if="props.row.dueDate != null" class="tag is-dark">
                                    {{ new Date(props.row.dueDate).toLocaleDateString() }}
                                </span>
                                <span v-else class="tag is-danger">
                                    N/A
                                </span>
                            </b-table-column>


                            <!-- <b-table-column field="date" label="Date" :th-attrs="dateThAttrs" :td-attrs="columnTdAttrs" centered v-slot="props">
                                <span class="tag is-success">
                                    {{ new Date(props.row.date).toLocaleDateString() }}
                                </span>
                            </b-table-column> -->

                            <!-- <b-table-column label="Gender" :td-attrs="columnTdAttrs" v-slot="props">
                                <span>
                                    <b-icon
                                        v-if="props.row.id !== 'Total'"
                                        pack="fas"
                                        :icon="props.row.gender === 'Male' ? 'mars' : 'venus'">
                                    </b-icon>
                                    {{ props.row.gender }}
                                </span>
                            </b-table-column> -->

                            <template #empty>
                                <div class="has-text-centered">No records</div>
                            </template>

                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            isLoading: false,
            data: []
        }
    },
    methods: {

    },
    async beforeMount(){
        await this.$http.get('https://rb-portal-backend.herokuapp.com/rb2project').then((res) => {
            this.data = res.data
        }).catch((e) => {
            // add error handling
            console.log(e)
        })
    }
}
</script>

<style>
.icon-text{
    vertical-align: middle !important;
}
.icon-text.icon{
        vertical-align: middle !important;

}
</style>