<template>
  <div>
    <div class="columns">
      <div class="column has-text-centered">
        <div class="container">
          <h1 class="is-size-2">
            {{ component.name }}
          </h1>
        </div>
      </div>
    </div>
    <section class="section">
      <div class="columns">
        <div class="column">
          <div class="container">
            <h1 class="is-size-4 has-text-centered">
              Component Details
            </h1>
            <div class="tile is-parent is-vertical">
              <article class="tile is-child notification is-medium-grey">
                <p>{{ component.description }}</p>
              </article>
            </div>
          </div>
        </div>
        <div class="column has-text-centered">
          <div class="container">
            <h1 class="is-size-4 has-text-centered">
              Component Image
            </h1>
            <figure
              v-if="component.img != null"
              class="image is-square"
              @click="showSingle(component.img)"
            >
              <img :src="component.img" />
            </figure>
            <figure v-else class="image is-square">
              <img
                src="https://1080motion.com/wp-content/uploads/2018/06/NoImageFound.jpg.png"
              />
            </figure>
          </div>

          <!-- <div class="container">
                        <b-image
                            v-if="component.img != null"
                            :src="component.img"
                            ratio="16by9"
                            :responsive="true"
                            @click.native="showSingle(component.img)"
                        ></b-image>
                        <b-image
                            v-else
                            src="https://1080motion.com/wp-content/uploads/2018/06/NoImageFound.jpg.png"
                            ratio="16by9"
                            :responsive="true"
                        ></b-image>                                             
                    </div> -->
        </div>
      </div>
    </section>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="imgs"
      :index="0"
      @hide="handleHide"
    >
    </vue-easy-lightbox>
    <div style="margin-top: 12%; float:right; position: relative; ">
      <b-button
        rounded
        class="is-warning absposition"
        size="is-large"
        @click="openUnitEditModal(component)"
      >
        <i class="fad fa-edit"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
import EditComponentModal from "@/components/modal/EditComponentModal";

export default {
  data() {
    return {
      component: {},
      imgs: "",
      visible: false,
    };
  },
  methods: {
    async getInformation() {
      await this.$http
        .get(
          "https://rb-portal-backend.herokuapp.com/component/" +
            this.$route.params.componentId
        )
        .then((res) => {
          this.component = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showSingle(imgSrc) {
      this.imgs = imgSrc;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    async openUnitEditModal(component) {
      this.$buefy.modal.open({
        parent: this,
        component: EditComponentModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          component: component,
        },
        events: {
          "success-event": async () => {
            this.$buefy.notification.open({
              message: "Succesfully updated",
              type: "is-success",
              position: "is-bottom",
              duration: 2000,
            });
            this.isLoading = true;

            this.getInformation();

            this.isLoading = false;
          },
          "failure-event": (error) => {
            this.$buefy.notification.open({
              message: error,
              type: "is-danger",
              position: "is-bottom",
              duration: 2000,
            });
          },
        },
      });
    },
  },
  async created() {
    await this.$http
      .get(
        "https://rb-portal-backend.herokuapp.com/component/" +
          this.$route.params.componentId
      )
      .then((res) => {
        this.component = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style scoped>
.absposition {
  position: fixed;
  right: 5px;
  bottom: 5px;
}
</style>
