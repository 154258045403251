<template>
  <b-navbar type="is-dark">
    <template #brand>
      <b-navbar-item tag="div">
        <img
          src="@/assets/RB_Logo.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/panel/projects' }">
        Projects
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/panel/components' }">
        Components
      </b-navbar-item>
    </template>
    <template #end>
      <div class="buttons mr-2">
        <a class="button is-primary" v-if="!authComputed" @click="logout">
          <strong>Logout</strong>
        </a>
      </div>
    </template>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push({ path: "/" });
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push({name: 'Login'})
        });
    },
  },
  created() {},
};
</script>
<style></style>
