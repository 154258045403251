<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Create Component</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column has-text-centered">
            <b-field label="Component Name">
              <b-input v-model="name"></b-input>
            </b-field>
            <b-field label="Description">
              <textarea
                class="textarea"
                placeholder="10 lines of textarea"
                rows="10"
                v-model="description"
              ></textarea>
            </b-field>
            <b-field label="Simple">
              <b-select placeholder="Type" v-model="selectedType">
                <option
                  v-for="option in types"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Image">
              <b-upload v-model="dropFile" expanded drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>Drop your files here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags">
              <span v-if="dropFile" class="tag is-primary">
                {{ dropFile.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile()"
                ></button>
              </span>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="">
          <button class="button is-primary" @click="addComponent()">
            Add Component
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      description: null,
      dropFile: null,
      types: [],
      selectedType: "",
    };
  },
  methods: {
    addComponent() {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("type", this.selectedType);
      formData.append("file", this.dropFile);
      this.$http
        .post("https://rb-portal-backend.herokuapp.com/component/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$emit("success-event");
          this.$emit("close");
        })
        .catch((e) => {
          this.$emit("failure-event", e);
        });
    },
    deleteDropFile() {
      this.dropFile = null;
    },
  },
  created() {
    this.$http
      .get("https://rb-portal-backend.herokuapp.com/component-type")
      .then((res) => {
        console.log(res.data);

        this.types = res.data;
      });
  },
};
</script>

<style></style>
