<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Edit Component</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column has-text-centered">
            <b-field label="Component Name">
              <b-input v-model="name"></b-input>
            </b-field>
            <b-field label="Description">
              <textarea
                class="textarea"
                placeholder="10 lines of textarea"
                rows="10"
                v-model="description"
              ></textarea>
            </b-field>
            <b-field label="Type">
              <b-select placeholder="Type" v-model="selectedType">
                <option
                  v-for="option in types"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Image">
              <b-upload v-model="dropFile" expanded drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>
                      Drop or click to upload. This will replace the existing
                      image.
                    </p>
                  </div>
                </section>
              </b-upload>
              <div class="tags">
                <span v-if="dropFile" class="tag is-primary">
                  {{ dropFile.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile()"
                  ></button>
                </span>
              </div>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="">
          <button class="button is-primary" @click="editComponent()">
            Submit
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: this.component.name,
      description: this.component.description,
      dropFile: null,
      types: [],
      selectedType: "",
    };
  },
  methods: {
    editComponent() {
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("type", this.selectedType);

      console.log(this.selectedType);

      if (this.dropFile != null) {
        formData.append("file", this.dropFile);
      }

      this.$http
        .patch(
          "https://rb-portal-backend.herokuapp.com/component/" +
            this.component.id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$emit("success-event");
          this.$emit("close");
        })
        .catch((e) => {
          this.$emit("failure-event", e);
        });
    },
    deleteDropFile() {
      this.dropFile = null;
    },
  },
  created() {
    this.$http
      .get("https://rb-portal-backend.herokuapp.com/component-type")
      .then((res) => {
        this.types = res.data;
        this.selectedType = this.component.type.id;
      });
  },
  props: ["component"],
};
</script>

<style></style>
