<template>
      <div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title has-text-centered">Installation Signature</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="columns is-centered">
                        <div class="column has-text-centered">
                            <div v-for="(component, index) in unitComponents" :key="index">
                                <div class="is-block">
                                    <p> <span>{{component.component.name}}</span>: <span>{{component.qty}}</span></p>
                                </div>
                            </div>
                            <b-field>
                                <b-checkbox v-model="approval" size="is-medium">I hereby attest that the above unit has been checked, pre-installation, and that the above components have been prepared for installation</b-checkbox>
                            </b-field>                            
                        </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button class="is-primary" @click="send()">Send</b-button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    components:{
    },
    data(){
        return{
            approval: false
        }
    },
    computed:{
        userId(){
            return this.$store.state.user.id
        }
    },
    props: ['barcode', 'unitComponents'],
    methods: {
        async send(){
            if(this.approval === true) {
                await this.$http.patch('https://rb-portal-backend.herokuapp.com/source/' + this.$route.params.barcode + '/install-signature', {
                    signatureDate: new Date(),
                    signer: this.userId
                }).then(() => {
                    this.$emit('success-event')
                    this.$emit('close');
                }).catch((e) => {
                    this.$emit('failure-event', e)
                })
            } else {
                this.$buefy.notification.open({
                    message: 'You must confirm you have accounted for all parts',
                    type: 'is-danger',
                    position: 'is-bottom',
                    duration: 2000
                })
            }

        }
    },
}
</script>

<style scoped>
.signature-box {
  border-radius: 25px;
  background: #ededed;
  padding: 20px;
}
</style>