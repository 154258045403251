import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import ProjectList from '@/components/ProjectList'
import UnitList from '@/components/UnitList'
import Unit from '@/components/Unit'
import ComponentList from '@/components/ComponentList'
import Component from '@/components/Component'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Login,
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue') 
    meta: {
      requiresAuth: false,
      title: "Login"
    }    
  },
  {
    path: '/panel',
    component: Home,
    children: [
      {
        path: '/panel/projects',
        component: ProjectList,
        meta: {
          requiresAuth: true,
          title: "Projects List"
        }
      },
      {
        path: '/panel/projects/:project',
        name: "Project",
        component: UnitList,
        meta: {
          requiresAuth: true,
          title: "Unit List"
        }
      },
      {
        path: '/panel/projects/:project/:barcode',
        component: Unit,
        name: "UnitView",
        meta: {
          requiresAuth: true,
          title: "Unit List"
        }
      },                       
      {
        path: '',
        component: ProjectList,
        meta: {
          requiresAuth: true,
          title: "Projects List"
        }
      },
      {
        path: '/panel/components',
        component: ComponentList,
        meta: {
          requiresAuth: true,
          title: "Component List"
        }
      },
      {
        path: '/panel/components/:componentId',
        name: "Component",
        component: Component,
        meta: {
          requiresAuth: true,
          title: "Component"
        }
      },             
    ]    
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
          name: 'Login'
      })
    } else {
      next()
    }
  }
 
   else {
    next() // make sure to always call next()!
  }
})


const DEFAULT_TITLE = 'Riviera Bronze Portal';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router
