import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    token: null
  },
  mutations: {
    SET_USER_DATA (state, userData) {
      state.user = userData.user
      localStorage.setItem('user', JSON.stringify(userData.token))
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
      axios.defaults.headers.common['auth'] = userData.token
    },
    CLEAR_USER_DATA (state) {
      state.user = null
      state.token = null
      localStorage.removeItem('user')
      delete axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["auth"];
      // location.reload()
    }
  },
  actions: {
    register ({ commit }, credentials) {
      return axios
        .post('https://rb-portal-backend.herokuapp.com/user', credentials)
        .then(({ data }) => {
          commit('SET_USER_DATA', data)
        })
    },
    login ({ commit }, credentials) {
      return axios
        .post('https://rb-portal-backend.herokuapp.com/rbauth/login', credentials)
        .then(({ data }) => {
          commit('SET_USER_DATA', data)
        }).catch((e) => {
          console.log(e)
        })
    },
    logout ({ commit }) {
      commit('CLEAR_USER_DATA')
    }
  },
  getters: {
    loggedIn (state) {
      return !!state.user
    },
    isAdmin (state) {
      if (state.user.role === "ADMIN" || state.user.role === "CAD") {
         return true
      } else {
        return false
      }
    }
  },
  plugins: [createPersistedState()]
})

export default store