<template>
  <div>
      <section class="section">
        <div class="columns is-centered">
            <div class="column is-6 has-text-centered">
                <router-link :to="{name:'Project', params: { project: unit.project.key }}" class="is-size-1"> 
                    PROJECT: {{ unit.project.key}}
                </router-link>                
                <h1 class="is-size-2">UNIT: {{ unit.barcode}}</h1>
            </div>
        </div>
      </section>
      <section class="section">
        <div class="">
            <div class="columns is-centered">
                <div class="column is-half my-box has-text-weight-bold is-uppercase">
                    <div class="has-text-centered">
                        <p class="is-size-4">Unit Details </p>
                    </div>
                    <br/>
                    <div class="has-text-centered">
                        <p>Unit #: {{ unit.unit }}</p>
                        <p>Description: {{ unit.description }}</p>
                        <p>Location: {{ unit.location }}</p>  
                        <p>Size: {{ unit.sqFt }} SF</p>
                    </div>
                </div>
                <!-- <div class="column">
                    <div class="has-text-centered">
                        <p class="is-size-4">CAD Drawing </p>
                    </div>
                    <br/>
                    <nav class="level">
                        <div class="level-item has-text-centered">
                        <figure class="image is-128x128">
                            <img src="https://bulma.io/images/placeholders/128x128.png"  @click="showSingle('https://bulma.io/images/placeholders/128x128.png')">
                        </figure>
                        </div>
                    </nav>
                </div> -->
          </div>
        </div>
      </section>
      <section class="section">       
          <div class="columns">
              <div class="column">
                <div class="my-box">
                    <b-table
                        :data="components"
                        :striped="true"
                        :loading="isLoading"
                        :bordered="true"
                        :mobile-cards="false"
                        :paginated="true"
                        :per-page="10"
                        :current-page.sync="currentPage"                        
                        >
                        <b-table-column field="component.name" label="Part" v-slot="props">
                            <router-link :to="{path: '/panel/components/' + props.row.component.id}" class="nav-link"> <p>{{ props.row.component.name }}</p></router-link>
                        </b-table-column>
                        <b-table-column field="qty" label="Quantity"  numeric v-slot="props">
                            <p>{{props.row.qty}} </p>
                        </b-table-column>
                        <b-table-column field="note" label="Note" v-slot="props">
                            <p>{{props.row.note}}</p>
                        </b-table-column>
                        <b-table-column label="Edit" v-slot="props" centered>
                            <div class="has-text-center">
                                <b-button type="is-warning" @click="openEditUnitComp(unit.barcode, props.row.component.id)"><i class="fad fa-edit"></i></b-button>
                            </div>
                        </b-table-column>                                                    
                        <b-table-column field="qty" label="Delete" v-slot="props" centered>
                            <div class="has-text-center">
                                <b-button type="is-danger" @click="removeComponent(unit.barcode, props.row.component.id)"><i class="fad fa-trash-alt"></i></b-button>
                            </div>
                        </b-table-column>                        
                        <template #empty>
                            <div class="has-text-centered">No records</div>
                        </template>
                        <template #bottom-left>
                            <div class="field buttons">
                                <button class="button is-warning is-pulled-right" @click="openUnitComponentEditModal(unit.barcode)"><i class="far fa-edit"></i>Edit Parts List</button>
                            </div>
                        </template>                        
                    </b-table>
                  </div>

              </div>
              <div class="column">
                  <div class="columns">
                      <div class="column ">
                            <div class="parent my-box">
                                <div class="div1"> 
                                    <p>Kitting Signature:</p>
                                </div>
                                <div class="div2"> 
                                    <p>Kitting Signature Date:</p>
                                </div>
                                <div class="div3"> 
                                    <p>Preinstallation Signature:</p>
                                </div>
                                <div class="div4"> 
                                    <p>Preinstallation Signature Date:</p>
                                </div>
                                <div class="div5">
                                    <p v-if="unit.kitSignedBy != null">{{ unit.kitSignedBy.name }}</p>                                    
                                    <button v-else class="button is-medium is-dark" @click="openKitSignatureModal(unit.barcode, components )"><i class="far fa-signature mr-2"></i>Click to Sign</button>                                    
                                </div>
                                <div class="div6"> 
                                    <p><b-tag v-if="unit.kitSignatureDate != null" type="is-success" size="is-large">{{ new Date(unit.kitSignatureDate).toLocaleString()}}</b-tag>
                                    <b-tag v-else type="is-warning" size="is-large">NO DATE</b-tag></p>                                    
                                </div>
                                <div class="div7"> 
                                     <p v-if="unit.installSignedBy != null">{{unit.installSignedBy.name}}</p>                                    
                                    <button class="button is-medium is-dark" v-else @click="openInstallSignatureModal(unit.barcode, components)"><i class="far fa-signature fa-fw mr-2"></i>Click to Sign</button>
                                </div>
                                <div class="div8"> 
                                    <b-tag  v-if="unit.installSignatureDate != null" type="is-success" size="is-large">{{new Date(unit.installSignatureDate).toLocaleString()}} </b-tag>
                                    <b-tag v-else type="is-warning" size="is-large">NO DATE</b-tag>
                                </div>
                            </div>                   
                      </div>
                  </div>
              </div>
          </div>
      </section>
    <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="0"
        @hide="handleHide"
    >
    </vue-easy-lightbox>      
  </div>
</template>

<script>
import KitSignature from '@/components/modal/KitSignature'
import InstallSignature from '@/components/modal/InstallSignature'
import EditUnitComponents from '@/components/modal/EditUnitComponents'
import UnitDetailModal from '@/components/modal/UnitDetailModal'

export default {
    data(){
        return{
            imgs: '',
            isLoading: false,
            visible: false,
            currentPage: 1,
            events: [],
            unit: {},
            components: []
        }
    },
    methods: {
        showSingle(imgSrc) {
            this.imgs = imgSrc
            this.show()
        },
        show() {
        this.visible = true
        },
        handleHide() {
        this.visible = false
        },
        openUnitComponentEditModal(barcode){
            this.$buefy.modal.open({
                parent: this,
                component: EditUnitComponents,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    barcode: barcode
                },
                events: {
                    'success-event': async () => {
                        this.$buefy.notification.open({
                            message: 'Succesfully updated',
                            type: 'is-success',
                            position: 'is-bottom',
                            duration: 2000
                        })
                        this.isLoading = true;
                        const returnData = await this.$http.get('https://rb-portal-backend.herokuapp.com/unit-component/' + this.$route.params.barcode)
                        this.components = returnData.data
                        this.isLoading = false
                    },
                    'failure-event': (error) => {
                        this.$buefy.notification.open({
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom',
                            duration: 2000
                    })                  
                }
                }
            })
        },
        openEditUnitComp(barcode, unitId){
            this.$buefy.modal.open({
                parent: this,
                component: UnitDetailModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    barcode: barcode,
                    unitId: unitId
                },
                events: {
                    'success-event': async () => {
                        this.$buefy.notification.open({
                            message: 'Succesfully updated',
                            type: 'is-success',
                            position: 'is-bottom',
                            duration: 2000
                        })
                        this.getInformation()
                    },
                    'failure-event': (error) => {
                        this.$buefy.notification.open({
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom',
                            duration: 2000
                    })                  
                }
            }
            })
        },                
        openKitSignatureModal(barcode, unitComponents){
            this.$buefy.modal.open({
                parent: this,
                component: KitSignature,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    barcode: barcode,
                    unitComponents: unitComponents
                },
                events: {
                    'success-event': async () => {
                        this.$buefy.notification.open({
                            message: 'Succesfully updated',
                            type: 'is-success',
                            position: 'is-bottom',
                            duration: 2000
                        })
                        this.isLoading = true;
                        this.getInformation()
                        this.isLoading = false
                    },
                    'failure-event': (error) => {
                        this.$buefy.notification.open({
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom',
                            duration: 2000
                    })                  
                }
                }
            })
        },
        openInstallSignatureModal(barcode, unitComponents){
            this.$buefy.modal.open({
                parent: this,
                component: InstallSignature,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    barcode: barcode,
                    unitComponents: unitComponents
                },
                events: {
                    'success-event': async () => {
                        this.$buefy.notification.open({
                            message: 'Succesfully updated',
                            type: 'is-success',
                            position: 'is-bottom',
                            duration: 2000
                        })
                        this.isLoading = true;
                        
                        this.getInformation()

                        this.isLoading = false
                    },
                    'failure-event': (error) => {
                        this.$buefy.notification.open({
                            message: error,
                            type: 'is-danger',
                            position: 'is-bottom',
                            duration: 2000
                    })                  
                }
                }
            })
        },
        async removeComponent(barcode, componentId){
                this.$buefy.dialog.confirm({
                    title: 'Remove Component From Unit',
                    message: 'Are you sure you want to <b>remove</b> this component from this unit?',
                    confirmText: 'Remove',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                    await this.$http
                    .delete('https://rb-portal-backend.herokuapp.com/unit-component/' + barcode + '/' + componentId)
                        .then(async () => {
                            this.$buefy.notification.open({
                                message: "Successfully Deleted Units",
                                type: 'is-success',
                                position: 'is-bottom',
                                duration: 2000                                  
                            })
                            this.getInformation()

                        })
                        .catch((e) => {
                            this.$buefy.notification.open({
                                message: e,
                                type: 'is-danger',
                                position: 'is-bottom',
                                duration: 2000
                        })        
                    })
                }
            })
        },
        async getInformation(){
            await this.$http.get('https://rb-portal-backend.herokuapp.com/source/' + this.$route.params.barcode).then((res) => {
                this.unit = res.data      
                }).catch((e) => {
                    this.$buefy.notification.open({
                        message: e,
                        type: 'is-danger',
                        position: 'is-bottom',
                        duration: 2000
                })        
            })
            await this.$http.get('https://rb-portal-backend.herokuapp.com/unit-component/' + this.$route.params.barcode).then((res) => {
                this.components = res.data
            })     
        }                  
    },
    async created(){
        await this.$http.get('https://rb-portal-backend.herokuapp.com/source/' + this.$route.params.barcode).then((res) => {
            this.unit = res.data      
            }).catch((e) => {
                this.$buefy.notification.open({
                    message: e,
                    type: 'is-danger',
                    position: 'is-bottom',
                    duration: 2000
            })        
        })
        await this.$http.get('https://rb-portal-backend.herokuapp.com/unit-component/' + this.$route.params.barcode).then((res) => {
            this.components = res.data
        })        
    }
}
</script>

<style scoped>
.my-box{
  border-radius: 25px;
  background: #eeeded;
  padding: 20px;
}

.parent {
display: grid;
grid-template-columns:0.2fr 1fr;
grid-template-rows: repeat(4, 1fr);
column-gap: 0px;
row-gap: 5px;
}

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 2 / 1 / 3 / 2; }
.div3 { grid-area: 3 / 1 / 4 / 2; }
.div4 { grid-area: 4 / 1 / 5 / 2; }
.div5 { grid-area: 1 / 2 / 2 / 3; }
.div6 { grid-area: 2 / 2 / 3 / 3; }
.div7 { grid-area: 3 / 2 / 4 / 3; }
.div8 { grid-area: 4 / 2 / 5 / 3; }
</style>