<template>
  <div>
    <div class="modal-card wide">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">
          Edit Unit Components {{ barcode }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <!-- <b-field label="Filter" custom-class="is-small" label-position="on-border">
                            <b-input v-model="name"></b-input>
            </b-field> -->
          </div>
        </div>
        <div class="columns is-centered is-mobile">
          <div class="column" v-for="(part, index) in parts" :key="index">
            <p class="has-text-centered">{{ part.category }}</p>
            <div v-for="(component, index) in part.data" :key="index">
              <b-field :label="component.name" custom-class="is-small">
                <b-numberinput
                  v-model="component.qty"
                  size="is-small"
                  controls-position="compact"
                >
                </b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="">
          <button class="button is-primary" @click="updateComponents()">
            Update
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      parts: [],
      unitParts: null,
    };
  },
  props: ["barcode"],
  methods: {
    chunkify(a, n, balanced) {
      if (n < 2) {
        return [a];
      }
      var len = a.length,
        out = [],
        i = 0,
        size;
      if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
          out.push(a.slice(i, (i += size)));
        }
      } else if (balanced) {
        while (i < len) {
          size = Math.ceil((len - i) / n--);
          out.push(a.slice(i, (i += size)));
        }
      } else {
        n--;
        size = Math.floor(len / n);
        if (len % size === 0) size--;
        while (i < size * n) {
          out.push(a.slice(i, (i += size)));
        }
        out.push(a.slice(size * n));
      }
      return out;
    },
    async updateComponents() {
      const map1 = this.parts.flatMap((x) => x.data);

      console.log(map1);

      const newArray = map1.filter(function(el) {
        return el.qty >= 1;
      });
      const newerArray = [];
      for (let i = 0; i < newArray.length; i++) {
        newerArray.push({
          source: this.barcode,
          component: newArray[i].id,
          qty: newArray[i].qty,
        });
      }
      console.log(newerArray);

      await this.$http
        .post("https://rb-portal-backend.herokuapp.com/unit-component/multi", {
          inputArray: newerArray,
        })
        .then(() => {
          this.$emit("success-event");
          this.$emit("close");
        })
        .catch((e) => {
          this.$emit("failure-event", e);
        });
    },
  },
  async created() {
    let val = await this.$http.get(
      "https://rb-portal-backend.herokuapp.com/component-type"
    );
    let val2 = val?.data;
    console.log(val2);

    let unitPartHolder = await this.$http.get(
      "https://rb-portal-backend.herokuapp.com/unit-component/" +
        this.$route.params.barcode
    );

    this.unitParts = unitPartHolder.data;

    for (let v of val2) {
      let pushValue = v.componentsOfType.map(function(el) {
        var o = Object.assign({}, el);
        o.qty = 0;
        return o;
      });
      this.parts.push({ category: v.name, data: pushValue });
    }

    for (let i = 0; i < this.parts.length; i++) {
      for (let j = 0; j < this.parts[i].data.length; j++) {
        for (let k = 0; k < this.unitParts.length; k++) {
          if (this.parts[i].data[j].id === this.unitParts[k].component.id) {
            this.parts[i].data[j].qty = this.unitParts[k].qty;
          }
        }
      }
    }

    console.log(this.parts);
  },
};
</script>

<style scoped>
.wide {
  min-width: 50vw;
}
</style>
