<template>
  <div class="login-background">
        <section class="hero is-gradient is-fullheight">
        <div class="hero-body">
            <div class="container">
            <div class="columns is-centered">
                <div class="column is-5-tablet is-4-desktop is-3-widescreen">
                    <LoginForm/>
                </div>
            </div>
            </div>
        </div>
        </section>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from '../components/LoginForm.vue'

export default {
  components: {
    LoginForm
  }
}
</script>


<style>
.is-gradient{
background: #D95B37;
background: -webkit-radial-gradient(bottom left, #D95B37, #A59C7B);
background: -moz-radial-gradient(bottom left, #D95B37, #A59C7B);
background: radial-gradient(to top right, #D95B37, #A59C7B);
}
</style>