<template>
  <section class="pt-6">
    <div class="project-list-wrapper">
      <div class="columns">
        <div class="column">
          <div class="container">
            <b-table
              :data="data"
              :bordered="true"
              :striped="true"
              :hoverable="true"
              :loading="isLoading"
              :focusable="false"
              :mobile-cards="false"
              :paginated="true"
              :per-page="10"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              :pagination-simple="false"
              pagination-position="bottom"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
              <b-table-column label="Image" v-slot="props">
                <figure v-if="props.row.img != null" class="image is-16by9">
                  <img :src="props.row.img" />
                </figure>
                <figure v-else class="image is-16by9">
                  <img src="https://bulma.io/images/placeholders/128x128.png" />
                </figure>
              </b-table-column>
              <b-table-column
                field="name"
                label="Name"
                v-slot="props"
                searchable
              >
                <router-link
                  :to="{ path: 'components/' + props.row.id }"
                  class="nav-link"
                >
                  <span class="icon-text">
                    <span>{{ props.row.name }}</span>
                  </span>
                </router-link>
              </b-table-column>
              <template #bottom-left>
                <div class="buttons">
                  <button
                    class="button is-primary"
                    @click="openAddComponentModal()"
                  >
                    <i class="fad fa-plus-circle mr-2"></i>New Component
                  </button>
                </div>
              </template>
              <template #empty>
                <div class="has-text-centered">No records</div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AddComponent from "@/components/modal/AddComponent.vue";
export default {
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  methods: {
    openAddComponentModal() {
      this.$buefy.modal.open({
        parent: this,
        component: AddComponent,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        events: {
          "success-event": async () => {
            this.$buefy.notification.open({
              message: "Succesfully updated",
              type: "is-success",
              position: "is-bottom",
              duration: 2000,
            });
            this.isLoading = true;
            this.getInformation();
            this.isLoading = false;
          },
          "failure-event": (error) => {
            this.$buefy.notification.open({
              message: error,
              type: "is-danger",
              position: "is-bottom",
              duration: 2000,
            });
          },
        },
      });
    },
    async getInformation() {
      await this.$http
        .get("https://rb-portal-backend.herokuapp.com/component")
        .then((res) => {
          this.data = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  async created() {
    await this.$http
      .get("https://rb-portal-backend.herokuapp.com/component")
      .then((res) => {
        this.data = res.data;
        for (const d of this.data) {
          // if(d.img){
          //     let image =  Buffer.from(d.img.data, 'binary').toString('base64')
          //     d.src = `data:image/png.toLowerCase()};base64,${image}`;
          // } else {
          //     d.src = null
          // }
          d.src = null;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>
