import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import Axios from 'axios'
import VueSignaturePad from 'vue-signature-pad';
import VueEasyLightbox from 'vue-easy-lightbox'

Vue.use(VueEasyLightbox)
Vue.use(VueSignaturePad);
Vue.prototype.$http = Axios;
Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
