<template>
      <div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title has-text-centered">Edit Unit-Component</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="columns is-centered">
                    <div class="column is-half has-text-left">
                        <div>
                            <p>{{ data.source.barcode }}</p>
                            <p>{{ data.component.name }}</p>
                        </div>
                        <b-field label="Quantity">
                            <b-field>
                                <b-numberinput  min="1" v-model="data.qty" controls-position="compact" controls-alignment="center"/>
                            </b-field>
                        </b-field>
                        <b-field label="Note">
                            <textarea class="textarea" v-model="data.note" rows="5"></textarea>
                        </b-field>                                                      
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="columns is-centered">
                    <div class="column is-one-half is-offset-one-quarter">
                        <button class="button is-primary" @click="editValues(data.source.barcode, data.component.id, data.qty, data.note)">Save</button>
                    </div>
                </div>
            </footer>
        </div>
    </div>  
</template>

<script>
export default {
    data(){
        return{
            data: {}

        }
    },
    methods:{
        async editValues(a, b, c, d){
            let retVal = await this.$http.patch('https://rb-portal-backend.herokuapp.com/unit-component', {
                source: a,
                component: b,
                qty: c,
                note: d
            }).then(() => {
                this.$emit('success-event')
                this.$emit('close')
            }).catch((e) => {
                this.$emit('failure-event', e)
                this.$emit('close')
            })

            console.log(retVal)
        },
    },
    props: ['barcode', 'unitId'],
    async created(){
        try{
            this.data = await this.$http.get('https://rb-portal-backend.herokuapp.com/unit-component/' + this.barcode + '/' + this.unitId).then((res) => {
                return res.data
            }).catch((e) => {
                console.log(e)
            })
            console.log(this.data)
        } catch (e){
            console.log(e)
        }
    }
    }
</script>

<style>

</style>