<template>
        <div class="columns">
            <div class="column has-background-white pl-0">
                <div class="columns mt-2 mb-4">
                    <div class="column is-8 is-offset-2 has-text-centered">
                        <h1 class="title">{{project.name}}</h1>
                        <p class="is-size-5">Promise Date: {{formatDate}}</p>
                        <p class="is-size-5"># Of Units: {{units.length}}</p>
                    </div>
                    <div class="column is-1">
                        <div class="button is-success" @click="getBOM()">
                            GET BOM
                        </div>
                    </div>
                </div>
                <div class="is-tall container">
                        <b-table :data="units" :paginated="true" :per-page="20" current-page.sync="1" striped :bordered="true" :mobile-cards="false">
                            <b-table-column field="barcode" label="Barcode" v-slot="props" sortable searchable>
                                <router-link :to="{name: 'UnitView', params: {key: $route.params.project, barcode: props.row.barcode} }" class="nav-link"> <p>{{ props.row.barcode }}</p></router-link>
                            </b-table-column>
                            <b-table-column field="description" label="Description" v-slot="props" searchable>
                                {{ props.row.description }}
                            </b-table-column>
                            <b-table-column field="location" label="Location" v-slot="props" sortable>
                                {{ props.row.location }}
                            </b-table-column>
                            <b-table-column label="Type" v-slot="props" sortable>
                                {{ props.row.type }} 
                            </b-table-column>  
                            <b-table-column label="Swing" v-slot="props" sortable>
                                {{ props.row.swing }}
                            </b-table-column>                                                                                  
                    </b-table>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    data() {
        return {
            project: {},
            units: [],
            unitExp: [],
            approvedData: [],
            approveSwitch: true
        }
    },
    computed: {
    formatDate: function () {
        const date = new Date(this.project.dueDate);
        if (!isNaN(date.getTime())) {
            const day = date.getDate().toString();
            const month = (date.getMonth() + 1).toString();
            return (month[1] ? month : '0' + month[0]) + '/' +
            (day[1] ? day : '0' + day[0]) + '/' + 
            date.getFullYear();
            } else {
                return "00/00/0000"
            }
        }
    },
    methods:{
        getData(){
            this.$http.get('https://rb-portal-backend.herokuapp.com/rb2project/' + this.$route.params.project).then((res) => {
                this.project = res.data
                this.units = res.data.sources
            })            
        },
        getBOM(){
            this.$http.get('https://rb-portal-backend.herokuapp.com/rb2project/' + this.$route.params.project + '/BOM', {
            responseType: 'blob'
                }).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    let yourDate = new Date()
                    const offset = yourDate.getTimezoneOffset()
                    yourDate = new Date(yourDate.getTime() - (offset*60*1000))
                    const retdate = yourDate.toISOString().split('T')[0]
                    link.setAttribute(
                    'download',
                    `${this.project.name}-BOM-${retdate}.xlsx`
                    )
                    document.body.appendChild(link)
                    link.click()
                });
        }
    },
    async beforeMount() {
        this.getData()
    },
}
</script>

<style scoped>
</style>