<template>
  <div class="home-wrapper">
      <navbar></navbar>
        <router-view></router-view> 
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
name: "Home",
components:{
    Navbar
}
}
</script>

<style>

</style>